//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import Select from '~/components/ui/Select.vue';
import DateRange from '~/components/ui/DateRange.vue';

import constant from '~/const';

export default {
  components: {
    Select,
    DateRange,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        createdFrom: '',
        createdTo: '',
        type: [],
        currency: [],
        status: [],
      },
      isFirstSearch: true,
      constant,
    };
  },

  computed: {
    ...mapState('transactionsHistory', ['filterLists']),
    ...mapState('tradersCurrencies', { currencies: 'data' }),
  },

  watch: {
    filters: {
      async handler(data) {
        const { isFirstSearch } = this;
        const sortData = {
          sort: 'dateCreated',
          sortDesc: !Number(data.searchString),
        };

        await this.setSort(sortData);
        if (!isFirstSearch) {
          await this.setFilters(data);
          this.$emit('update');
        }
      },
      deep: true,
    },
  },

  mounted() {
    /* eslint-disable no-nested-ternary */
    const {
      historySearchString,
      historyOperationTypeList,
      historySide,
      historyTransactionStatusList,
    } = this.$route.query;
    const searchString = historySearchString;
    const type = historyOperationTypeList
      ? typeof historyOperationTypeList === 'string'
        ? [historyOperationTypeList]
        : historyOperationTypeList
      : [];
    const side = historySide;
    const status = historyTransactionStatusList
      ? typeof historyTransactionStatusList === 'string'
        ? [historyTransactionStatusList]
        : historyTransactionStatusList
      : [];

    if (searchString || type.length || side || status.length) {
      this.$router.replace({ query: {} });
      this.isFirstSearch = false;
      this.filters = {
        ...this.filters,
        searchString: searchString || '',
        type: type.map((e) => Number(e)),
        side: Number(side),
        status: status.map((e) => Number(e)),
      };
    } else {
      this.setFilters({});
    }
  },

  methods: {
    ...mapActions('transactionsHistory', ['setFilters', 'setSort']),

    async firstSearch() {
      const { filters } = this;

      this.isFirstSearch = false;
      await this.setFilters(filters);
      this.$emit('update');
    },
  },
};
